
import "./services.css"
import backgroundImage from "../assets/DJI_0078.JPG"

export default function Services() {

    return (
        <div id="servicesContainer">
            <div id="services">
                <h1>Drone Surveyor</h1>
                <p id="about">
                    My names Luka Koens, I am a Sole trader based in Northcote point, I currently offer a number of services related to drone photography. 
                    Check out my facebook page to see some of my work 
                </p>
                <ul>
                    <li>- Roof Inspections</li>
                    <li>- Property Mapping and Photography</li>
                    <li>- Under Canopy Drone Photography</li>
                    <li>- Photogrametry & 3D modeling</li>
                    <li>- Automobile & Lifestyle Photography</li>
                </ul>
                {/* <p id="WIP">
                    This site is currently WIP, check back in a bit for web demonstrations of the photogrammetry techniques and more information regarding services offered
                </p>  */}
            </div>
            
            <div id="contact">
                <h1>Get in Touch</h1>
                <ul>
                    <li>Ph# - 0279072766</li>
                    <li>Email - luka.koens@gmail.com </li>
                    <li>Facebook - <a href="https://www.facebook.com/profile.php?id=61560752370170" target="_blank" rel="noopener noreferrer"> DroneSurveyor</a> </li>
                </ul>
            </div>

            <img src={backgroundImage} alt="Background" />
        </div>
    );
}

